<template>
  <div v-if="pageInit">
    <a-modal
      v-model:visible="showScanner"
      title="Scanner"
      :footer="null"
      wrapClassName="modal-with-height"
      :afterClose="onCloseScanner">
      <div class="loading">
        <a-space>
          <a-spin size="large" />
        </a-space>
      </div>
      <div v-if="showScanner">
        <StreamBarcodeReader
          @decode="onDecode"
          @loaded="onLoaded"
          ref="BarcodeReader"
        >
        </StreamBarcodeReader>
      </div>
    </a-modal>
    <a-modal v-model:visible="printingModal" :footer="null" wrapClassName="printingModal">
      <a :href="printingUrl" target="_blank">
        <button>
          {{ t('listRMA.printPackageListNow') }}
        </button>
      </a>
    </a-modal>
    <div class="home">
      <a-row style="margin-left: 5px">
        <a-col :sm="24" :md="24" :xl="24">
          <a-row>
            <a-col :sm="22" :md="8" :xl="8">
              <span style="margin-top: 10px"><b>{{ $t("route.ScanYourRMAProduct") }}</b></span>
              <a-input-search placeholder="" v-model:value="productScan" @pressEnter="onHandleProductScan" enter-button @search="onHandleProductScan">
                <template #prefix>
                  <a-button type="primary" @click="toggleScanner">
                    <template #icon>
                      <ScanOutlined />
                    </template>
                  </a-button>
                </template>
              </a-input-search>
            </a-col>
            <a-col :sm="21" :md="7" :xl="8" :offset="1">
              <span style="margin-top: 10px"><b>{{ $t("route.TrackingNumber") }}</b></span>
              <a-input-search placeholder="" v-model:value="trackingNumber" style="padding-left:10px" @search="onHandleTrackingInput">
                <template #prefix>
                  <BarcodeOutlined/>
                </template>
              </a-input-search>
            </a-col>
            <a-col :sm="23" :md="7" :xl="4" :offset="1" style="text-align: 'center'; margin-top: 23px;">
              <a-button key="blankRMA" type="primary" @click="onPreparePackage" :disabled="!hasScannedItem">
                <template #icon>
                  <PlusOutlined />
                </template>
                {{ $t('route.ReceivedPackage') }}
              </a-button>
              <a-button type="link" @click="onClickClearAll">
                {{ $t('route.ClearAll') }}
              </a-button>
            </a-col>
          </a-row>
        </a-col>
        <LastProduct :lastItem="lastItem" :unScannedItemCount="UnScannedItemCount" :scannedItemCount="ScannedItemCount"></LastProduct>
      </a-row>
    </div>
    <ProductList :waitingPackageData="waitingPackageData" :state="receivedPackageState"></ProductList>
    <ProductDetail :stateData="receivedPackageState"></ProductDetail>
  </div>
  <div v-else>
    <a-space style="text-align:center;">
      <a-spin size="large" />
    </a-space>
  </div>
</template>
<script> 
  import { onMounted, createVNode, ref, computed, watch } from 'vue'
  import { Modal, message, notification } from 'ant-design-vue'
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import { UserOutlined, ScanOutlined, ExclamationCircleOutlined, UserSwitchOutlined, BarcodeOutlined, CalendarOutlined, FileSearchOutlined, BorderlessTableOutlined, PlusOutlined } from '@ant-design/icons-vue';
  import { formatDate } from '@/utils/utils';
  import { StreamBarcodeReader } from "vue-barcode-reader";
  import ProductList from '@/components/rma/ProductList'
  import LastProduct from '@/components/rma/LastProduct'
  import ProductDetail from '@/components/rma/ProductDetail'
  import receivedPackageState from './state/ReceivedPackage'
  import { useI18n } from 'vue-i18n';

  export default {
    components: {
      UserOutlined,
      UserSwitchOutlined,
      BarcodeOutlined,
      CalendarOutlined,
      FileSearchOutlined,
      BorderlessTableOutlined,
      ScanOutlined,
      ProductList,
      LastProduct,
      ProductDetail,
      PlusOutlined,
      StreamBarcodeReader
    },
    setup() { 
      const { getRmaByStoreIDStatus, updateRmaStatus, printPackageList, rmas, getAllRma} = useRmaRepositories()
      const waitingPackageData = ref([])
      const scannedItem = ref([])
      const productScan = ref("")
      const lastItem = ref({})
      const showScanner = ref(false)
      const printingUrl = ref("")
      const printingModal = ref(false)
      const { t ,locale } = useI18n({ useScope: 'global' })
      const BarcodeReader = ref()
      const pageInit = ref(false)

      const toggleScanner= () => {
        showScanner.value = !showScanner.value
      }

      const onLoaded = () => {}

      const onCloseScanner = () => {}

      const onDecode = (result) => {
        productScan.value = result
        onHandleProductScan()
      }

      watch(showScanner, (newValues, prevValues) => {
        if (!newValues) BarcodeReader.value.codeReader.stopStreams()
      })

      onMounted(async () => {
        // getAllRma()
        rmas.value = await getRmaByStoreIDStatus('?status_code=14')
        pageInit.value = true
      })

      const ScannedItemCount = computed(() => {
        return scannedItem.value.length
      });

      const UnScannedItemCount = computed(() => {
        const pendingItem = rmas.value.data.filter((data) => {
          return data.rma_status_id === 14
        })
        return pendingItem.length - scannedItem.value.length
      });

      const hasScannedItem = computed(() => {
        return scannedItem.value.length > 0
      });

      const hasRmaData = computed(() => {
        if (rmas && rmas.value && rmas.value.data) {
          return rmas.value.data.length > 0
        }
        return false
      });

      const trackingNumber = ref("")

      const reset = () => {
        scannedItem.value = []
        waitingPackageData.value = []
        trackingNumber.value = ""
        productScan.value = ""
        getAllRma()
      }

      const onHandleTrackingInput = () => {
        const foundItem = []
        rmas.value.data.map((data) => {
          if ((data.wh_carrier_code === trackingNumber.value) && (data.rma_status_id === 14)) {
            foundItem.push(data)
          }
        })

        if (foundItem.length === 0) {
          return notification.error({
            message: 'RMA not added',
            description: "RMA not found"
          })
        }

        const foundItemNotDuplicated = []
        foundItem.map((data) => {
          const duplicated = scannedItem.value.find((data) => {
            return data.wh_carrier_code === trackingNumber.value
          })
          if (duplicated) {
            notification.error({
              message: 'RMA added',
              description: "RMA already scanned"
            })
            return
          } else {
            data.is_scanned = true
            data.rma_id = data.RmaID
            data.receive_item_date = formatDate(data.receive_item_date)
            data.product_image = "https://imgcdn.apm-monaco.cn/products-images/" + data.product_sku + ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76"
            foundItemNotDuplicated.push(data)
          }
        })
        waitingPackageData.value = [...scannedItem.value, ...foundItemNotDuplicated]
        scannedItem.value = [...scannedItem.value, ...foundItemNotDuplicated]
      }

      // APMRMA-000179
      const onHandleProductScan = () => {
        const foundItem = rmas.value.data.find((data) => {
          return (data.RmaID === productScan.value) && (data.rma_status_id === 14)
        })

        const duplicated = scannedItem.value.find((data) => {
          return data.RmaID === productScan.value
        })

        if (duplicated) {
          notification.error({
            message: 'RMA added',
            description: "RMA already scanned"
          })
          return
        }

        if (!foundItem) {
          return notification.error({
            message: 'RMA not found',
            description: "Could not find any data in your search"
          })
        } else {
          notification.success({
            message: 'RMA found',
            description: "RMA added for sending out"
          })
        }

        waitingPackageData.value = waitingPackageData.value.filter((data) => {
          return foundItem.id !== data.id
        })

        foundItem.is_scanned = true
        foundItem.rma_id = foundItem.RmaID
        foundItem.receive_item_date = formatDate(foundItem.receive_item_date)
        foundItem.product_image = "https://imgcdn.apm-monaco.cn/products-images/" + foundItem.product_sku + ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76"
        waitingPackageData.value.unshift(foundItem)
        scannedItem.value.push(foundItem)

        lastItem.value = foundItem
        lastItem.value.imgsrc = "https://imgcdn.apm-monaco.cn/products-images/"+lastItem.value.product_sku+".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76"

        productScan.value = ""
      }

      const onClickClearAll = () => {
        Modal.confirm({
          title: 'Are you sure to cancel the tracking?',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            reset()
          },
          onCancel() {},
        });
      }

      const onPreparePackage = async () => {
        const arrayOfID = []
        const updateAllRma = scannedItem.value.map((row) => {
          arrayOfID.push(row.id)
          const post_data = {
            id: row.id,
            rma_status_id: 15,
            retail_carrier_code: trackingNumber.value
          }

          return updateRmaStatus(post_data).then((data) => {
            return data
          });
          // return updateRmaStatus(row.id, 15, trackingNumber.value).then((data) => {
          //   return data
          // });
          reset()
        });

        Promise.all(updateAllRma).then(async (data) => {
          for(let i = 0; i<data.length; i++){
            if(data.success){
              notification.success({
                message: 'RMA Updated',
                description: "RMA Updated"
              })
            }else{
              notification.error({
                message: data[i].message,
              })
            }
          }
          
          reset()
        }).catch((err) => {
          
          notification.error({
            message: err.message,
            description: err
          })
          reset()
        })
      }

      return {
        waitingPackageData,
        BarcodeOutlined,
        productScan,
        onHandleProductScan,
        hasScannedItem,
        trackingNumber,
        onPreparePackage,
        ScannedItemCount,
        UnScannedItemCount,
        lastItem,
        onClickClearAll,
        onDecode,
        onLoaded,
        StreamBarcodeReader,
        showScanner,
        toggleScanner,
        printingUrl,
        printingModal,
        onHandleTrackingInput,
        hasRmaData,
        receivedPackageState,
        locale, t,
        onCloseScanner,
        BarcodeReader,
        pageInit
      }
    }
  }
</script>
<style lang="scss" scoped>
.waiting_package_data_row {
  background:#FF4D4D;
}

.waiting_package_data_row.is_scanned {
  background:#86D067;
}
.home::v-deep {
  padding-left: 10px;
  padding-right: 10px;
}

.ant-card .ant-card-body::v-deep {
  padding: 10px!important;
}

.ant-image::v-deep {
  float: right!important;
}

.wrapper2 {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.badge2 {
  display: inline-block;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
  min-width: 55px;
  text-align: center;
  background: #86D067;
  color: #fefefe;
}

.badge3 {
  display: inline-block;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
  min-width: 55px;
  text-align: center;
  background: #FF4D4E;
  color: #fefefe;
}

button:disabled::v-deep,
button[disabled]::v-deep {
  border: 1px solid #999999!important;
  background-color: #cccccc!important;
  color: #666666!important;
}

.loading {
  left:46%;
  top:50%;
  position: absolute;
}
</style>
<style lang="scss">
.scanner-container {
  .laser {
    top: 50%;
  }
}
.modal-with-height {
  .ant-modal-body {
    min-height:300px;
  }
}
.ant-input-search-enter-button {
  padding-top: 0px!important;
  padding-left: 0px!important;
  padding-bottom: 0px!important;
}
</style>